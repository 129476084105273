
import React, { useEffect } from 'react';
import { useFormStore } from '../../state/FormStore';
//import useThreeStore from '../../cameraStore';



export const Toolbar = () => {
    const setActive = useFormStore(state => state.setActive); // Get the setActive function from the Zustand store
    const activeTab = useFormStore(state => state.activeTab); // Get the activeTab from the Zustand store
    //const { cameraSettings, setCameraSettings } = useThreeStore();


    // Function to handle tab click and set active state
    const handleTabClick = tabName => {
        setActive(tabName);
        //changeCameraPosition(tabName);

    };
    /*     const changeCameraPosition = tabName => {
            let position = [0, 0, 5000];
            //setCameraSettings({ ...cameraSettings, position: position });
            //console.log(cameraSettings);
        }; */

    useEffect(() => {
        const rootFieldsets = document.querySelectorAll('fieldset[id^="root_"]');
        rootFieldsets.forEach(fieldset => {
            fieldset.style.display = 'none';
        });

        // Show the fieldset with the activeTab value
        const activeFieldset = document.getElementById(`root_${activeTab}`);
        if (activeFieldset) {
            activeFieldset.style.display = 'block';
        }

    }, [activeTab]);

    return (
        <nav className="bg-gray-900  justify-between w-3/12 h-screen">
            <div className="mt-10 mb-10 text-center ">
                <div>
                    <ul className='text-white text-xs'>
                        <li className={`mb-5 ${activeTab === 'maat' ? 'active' : ''}`}>
                            <a href="#" onClick={() => handleTabClick('maat')}>
                                <span>
                                    <svg
                                        className="fill-current h-3 w-3 mx-auto text-gray-300 hover:text-green-500"
                                        viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fillRule="evenodd" clipRule="evenodd" d="M11.5 3.04999C11.7485 3.04999 11.95 3.25146 11.95 3.49999V7.49999C11.95 7.74852 11.7485 7.94999 11.5 7.94999C11.2515 7.94999 11.05 7.74852 11.05 7.49999V4.58639L4.58638 11.05H7.49999C7.74852 11.05 7.94999 11.2515 7.94999 11.5C7.94999 11.7485 7.74852 11.95 7.49999 11.95L3.49999 11.95C3.38064 11.95 3.26618 11.9026 3.18179 11.8182C3.0974 11.7338 3.04999 11.6193 3.04999 11.5L3.04999 7.49999C3.04999 7.25146 3.25146 7.04999 3.49999 7.04999C3.74852 7.04999 3.94999 7.25146 3.94999 7.49999L3.94999 10.4136L10.4136 3.94999L7.49999 3.94999C7.25146 3.94999 7.04999 3.74852 7.04999 3.49999C7.04999 3.25146 7.25146 3.04999 7.49999 3.04999L11.5 3.04999Z" fill="#ffffff"></path> </g></svg>
                                </span>
                                Maat
                            </a>
                        </li>
                        <li className={`mb-5 ${activeTab === 'uitval_type' ? 'active' : ''}`}>
                            <a href="#" onClick={() => handleTabClick('uitval_type')}>
                                <span>
                                    <svg
                                        className="fill-current h-3 w-3 text-gray-300 mx-auto hover:text-green-500"
                                        viewBox="0 0 24 24"
                                    >
                                        <path
                                            d="M12 13H7v5h5v2H5V10h2v1h5v2M8
                          4v2H4V4h4m2-2H2v6h8V2m10 9v2h-4v-2h4m2-2h-8v6h8V9m-2
                          9v2h-4v-2h4m2-2h-8v6h8v-6z"
                                        ></path>
                                    </svg>
                                </span>
                                Uitval Type
                            </a>
                        </li>
                        <li className={`mb-5 ${activeTab === 'kleur' ? 'active' : ''}`}>
                            <a href="#" onClick={() => handleTabClick('kleur')}>
                                <span>
                                    <svg
                                        className="fill-current h-3 w-3 mx-auto text-gray-300 hover:text-green-500"
                                        viewBox="0 0 24 24"
                                    >
                                        <path
                                            d="M12 4a4 4 0 014 4 4 4 0 01-4 4 4 4 0 01-4-4 4 4 0
                          014-4m0 10c4.42 0 8 1.79 8 4v2H4v-2c0-2.21 3.58-4
                          8-4z"
                                        ></path>
                                    </svg>
                                </span>
                                Kleur
                            </a>
                        </li>
                        <li className={`mb-5 ${activeTab === 'dak' ? 'active' : ''}`}>
                            <a href="#" onClick={() => handleTabClick('dak')}>
                                <span>
                                    <svg
                                        className="fill-current h-3 w-3 text-gray-300 mx-auto hover:text-green-500"
                                        viewBox="0 0 24 24"
                                    >
                                        <path
                                            d="M12 13H7v5h5v2H5V10h2v1h5v2M8
                          4v2H4V4h4m2-2H2v6h8V2m10 9v2h-4v-2h4m2-2h-8v6h8V9m-2
                          9v2h-4v-2h4m2-2h-8v6h8v-6z"
                                        ></path>
                                    </svg>
                                </span>
                                DAK
                            </a>
                        </li>

                        {/*     <li className={`mb-5 ${activeTab === 'Floor' ? 'active' : ''}`}>
                            <a href="#" onClick={() => handleTabClick('Floor')}>
                                <span>
                                    <svg
                                        className="fill-current h-3 w-3 text-gray-300 mx-auto hover:text-green-500"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M22.775 8C22.9242 8.65461 23 9.32542 23 10H14V1C14.6746 1 15.3454 1.07584 16 1.22504C16.4923 1.33724 16.9754 1.49094 17.4442 1.68508C18.5361 2.13738 19.5282 2.80031 20.364 3.63604C21.1997 4.47177 21.8626 5.46392 22.3149 6.55585C22.5091 7.02455 22.6628 7.5077 22.775 8ZM20.7082 8C20.6397 7.77018 20.5593 7.54361 20.4672 7.32122C20.1154 6.47194 19.5998 5.70026 18.9497 5.05025C18.2997 4.40024 17.5281 3.88463 16.6788 3.53284C16.4564 3.44073 16.2298 3.36031 16 3.2918V8H20.7082Z"
                                            fill="currentColor"
                                        />
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M1 14C1 9.02944 5.02944 5 10 5C10.6746 5 11.3454 5.07584 12 5.22504V12H18.775C18.9242 12.6546 19 13.3254 19 14C19 18.9706 14.9706 23 10 23C5.02944 23 1 18.9706 1 14ZM16.8035 14H10V7.19648C6.24252 7.19648 3.19648 10.2425 3.19648 14C3.19648 17.7575 6.24252 20.8035 10 20.8035C13.7575 20.8035 16.8035 17.7575 16.8035 14Z"
                                            fill="currentColor"
                                        />
                                    </svg>
                                </span>
                            </a>
                            Floor
                        </li> */}
                        <li className={`mb-5 ${activeTab === 'front' ? 'active' : ''}`}>
                            <a href="#" onClick={() => handleTabClick('front')}>
                                <span>
                                    <svg
                                        className="fill-current h-3 w-3 text-gray-300 mx-auto hover:text-green-500"
                                        viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M12 3V21M22 12H15.5M15.5 12L19.5 16M15.5 12L19.5 8M2 12H8.5M8.5 12L4.5 16M8.5 12L4.5 8" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> </g></svg>
                                </span>
                            </a>
                            Front
                        </li>
                        {/*     <li className={`mb-5 ${activeTab === 'Left' ? 'active' : ''}`}>
                            <a href="#" onClick={() => handleTabClick('Left')}>
                                <span>
                                    <svg
                                        className="fill-current h-3 w-3 text-gray-300 mx-auto hover:text-red-500"
                                        fill="#000000" viewBox="0 0 52 52" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"><rect id="Rectangle" x="2" y="2" width="6.4" height="48" rx="1.6" /><rect id="Rectangle-2" data-name="Rectangle" x="11.6" y="18.8" width="38.4" height="14.16" rx="3.2" /></svg>
                                </span>
                            </a>
                            Left
                        </li>
                        <li className={`mb-5 ${activeTab === 'Right' ? 'active' : ''}`}>
                            <a href="#" onClick={() => handleTabClick('Right')}>
                                <span>
                                    <svg
                                        className="fill-current h-3 w-3 text-gray-300 mx-auto hover:text-green-500"
                                        fill="#000000" viewBox="0 0 52 52" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"><rect id="Rectangle" x="43.6" y="2" width="6.4" height="48" rx="1.6" /><rect id="Rectangle-2" data-name="Rectangle" x="2" y="18.8" width="37.6" height="14.4" rx="3.2" /></svg>
                                </span>
                            </a>
                            Right
                        </li>
                        <li className={`mb-5 ${activeTab === 'Led' ? 'active' : ''}`}>
                            <a href="#" onClick={() => handleTabClick('Led')}>
                                <span>
                                    <svg
                                        className="fill-current h-3 w-3 text-gray-300 mx-auto hover:text-green-500"
                                        fill="#000000" viewBox="0 0 8 8" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M3.41 0a.5.5 0 0 0-.13.06l-3 1.5a.5.5 0 1 0 .44.88l3-1.5a.5.5 0 0 0-.31-.94zm1 1.5a.5.5 0 0 0-.13.06l-4 2a.5.5 0 1 0 .44.88l4-2a.5.5 0 0 0-.31-.94zm0 2a.5.5 0 0 0-.13.06l-3 1.5a.5.5 0 0 0 .22.94h2a.5.5 0 0 0 .16-1l1.06-.56a.5.5 0 0 0-.31-.94zm-2.56 3.5a.5.5 0 0 0 .16 1h1a.5.5 0 1 0 0-1h-1a.5.5 0 0 0-.09 0 .5.5 0 0 0-.06 0z" transform="translate(1)"></path> </g></svg>
                                </span>
                            </a>
                            LED
                        </li> */}
                    </ul>
                </div>
            </div>
            {/*     <div className="mb-4">
                <a href="#">
                    <span>
                        <svg
                            className="fill-current h-3 w-3 text-gray-300 mx-auto hover:text-red-500"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M13 4.00894C13.0002 3.45665 12.5527 3.00876 12.0004 3.00854C11.4481 3.00833 11.0002 3.45587 11 4.00815L10.9968 12.0116C10.9966 12.5639 11.4442 13.0118 11.9965 13.012C12.5487 13.0122 12.9966 12.5647 12.9968 12.0124L13 4.00894Z"
                                fill="currentColor"
                            />
                            <path
                                d="M4 12.9917C4 10.7826 4.89541 8.7826 6.34308 7.33488L7.7573 8.7491C6.67155 9.83488 6 11.3349 6 12.9917C6 16.3054 8.68629 18.9917 12 18.9917C15.3137 18.9917 18 16.3054 18 12.9917C18 11.3348 17.3284 9.83482 16.2426 8.74903L17.6568 7.33481C19.1046 8.78253 20 10.7825 20 12.9917C20 17.41 16.4183 20.9917 12 20.9917C7.58172 20.9917 4 17.41 4 12.9917Z"
                                fill="currentColor"
                            />
                        </svg>
                    </span>
                </a>
            </div> */}
        </nav>
    );
};

//export default Toolbar;
