import { createRef } from 'react';
import Form from '@rjsf/core';
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import ImageRadioWidget from './CustomFIelds/RadioWidget';
import ColorSelectWidget from './CustomFIelds/ColorWidget';
import { useFormStore } from '../../state/FormStore';
import VerandaCalculator from '../../state/Calculations';

const formData = {
    dak: 'Polycarbonaat', //Glas
    breedte: 600,
    glassWidth: 80, // CONST
    uitval: 300,
    uitvalType: 'Midden staander',
    gutter: 'Klassiek',// 'Vierkant', 'Rond',
};

// Instantiate the VerandaCalculator object with the form data
/* const calculator = new VerandaCalculator(formData);

// Now you can call the calculation methods as needed
const TK8150Result = calculator.calculateTK8148();
console.log('TK8150 Result:', TK8150Result);

const TKGLS003Result = calculator.calculateTKGLS003();
console.log('TKGLS003 Result:', TKGLS003Result);

const tk8150Result = calculator.calculateTK8150();
console.log('TK8150 Result:', tk8150Result);

const tk8149Result = calculator.calculateTK8149();
console.log('TK8149 Result:', tk8149Result);

const tk8114Result = calculator.calculateTK8114();
console.log('TK8114 Result:', tk8114Result);

const tk8117Result = calculator.calculateTK8117();
console.log('TK8117 Result:', tk8117Result);

const calculateTK8113Result = calculator.calculateTK8113();
console.log('calculateTK8113 Result:', tk8117Result); */


const formRef = createRef();
const schema: RJSFSchema = {
    type: 'object',

    "properties": {
        "maat": {
            type: 'object',
            title: 'Maat',
            properties: {
                "breedte": {
                    "type": "number",
                    "title": "Breedte",
                    "default": 600,
                    "minimum": 200,
                    "maximum": 700
                },
                "uitval": {
                    "type": "number",
                    "title": "Uitval",
                    "default": 300,
                    "minimum": 200,
                    "maximum": 500

                },
                "doorloop_hoogte": {
                    "type": "number",
                    "title": "Doorloop Hoogte",
                    "default": 220,
                    "minimum": 220,
                    "maximum": 250

                },
            },
        },
        "uitval_type": {
            type: 'object',
            title: 'Uitval type',
            properties: {
                uitval_type_option: {
                    type: 'string',
                    title: ' ',
                    "default": "Buiten staander",
                    enum: [
                        'Buiten staander', 'Midden staander', 'Binnenkant staander', 'Buitenkant goot'
                    ], // Add your image options here
                },
            },
        },
        "kleur": {
            type: 'object',
            title: 'Kleur',
            properties: {
                kleur_option: {
                    type: 'string',
                    title: ' ',
                    "default": "Antraciet",
                    enum: [
                        'Antraciet', 'Creme', 'Black'
                    ], // Add your image options here
                },
            },
        },
        "dak": {
            type: 'object',
            title: 'Dak',
            properties: {
                dak_option: {
                    type: 'string',
                    title: ' ',
                    default: 'Helder - Polycarbonnat',
                    enum: [
                        'Helder - Polycarbonnat', 'Opaal - Polycarbonnat', 'Zonwerend - Polycarbonnat', 'Glas', 'Getint Glas', 'Melk Glas'
                    ], // Add your image options here
                },
            },
        },
        "front": {
            type: 'object',
            title: 'Frontside',
            properties: {
                goot_option: {
                    type: 'string',
                    title: 'Gutter',
                    default: 'Rond',
                    enum: [
                        'Klassiek', 'Vierkant', 'Rond'
                    ], // Add your image options here
                },
            },
        },
    }
};


const uiSchema = {
    'ui:classNames': 'darzo-input',
    maat: { // Correct placement of custom widget

    },
    uitval_type: { // Correct placement of custom widget
        uitval_type_option: { // Place the custom widget under kleur_option
            'ui:widget': 'ImageRadioWidget', // Correct path to custom widget
        }
    },
    kleur: { // Correct placement of custom widget
        kleur_option: { // Place the custom widget under kleur_option
            'ui:widget': 'ColorSelectWidget', // Correct path to custom widget
        }
    },
    dak: { // Correct placement of custom widget
        dak_option: { // Place the custom widget under kleur_option
            'ui:widget': 'ImageRadioWidget', // Correct path to custom widget
        }
    },
    front: { // Correct placement of custom widget
        goot_option: { // Place the custom widget under kleur_option
            'ui:widget': 'ImageRadioWidget', // Correct path to custom widget
        }
    },

};


const widgets = {
    ImageRadioWidget: ImageRadioWidget,
    ColorSelectWidget: ColorSelectWidget,
};

const onError = (errors) => alert(errors);


export const ConfigurationForm = () => {
    const { formData, setFormData } = useFormStore(); // Destructure the formData and setFormData functions from Zustand


    const handleChange = ({ formData, errors }) => {
        // Check if there are any errors
        if (errors && errors.length > 0) {
            // Handle errors here, such as displaying them to the user
            console.error('Form errors:', errors);
        } else {
            // Update form data only if there are no errors
            setFormData(formData);
        }
    };


    return (
        <div>
            <Form
                schema={schema}
                uiSchema={uiSchema}
                widgets={widgets}
                onChange={handleChange}
                /*     formData={formData} */
                onSubmit={(data) => console.log('Data submitted:', data)}
                validator={validator}
                onError={onError}
                ref={formRef}
                liveValidate={true}
            />
        </div>
    );
};

