// SideDrawer.js
import React, { useEffect } from 'react';
import { useFormStore } from '../../state/FormStore';
import { ConfigurationForm } from './ConfigurationForm';

export const SideDrawer = () => {
    const { activeTab, setFormData, formData, calculateSizes, sizes } = useFormStore();

    useEffect(() => {
        calculateSizes();
    }, [])

    return (
        <div className='bg-gray-800 desktop-configurator text-white w-10/12 justify-between py-6 px-4 overflow-x-hidden overflow-y-scroll h-screen'>
            <ConfigurationForm />
        </div>
    );
};

export default SideDrawer;
