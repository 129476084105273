// formStore.js
import create from 'zustand';

const getColorHex = (colorName) => {
    switch (colorName) {
        case 'Antraciet':
            return ['#353C40', 1]; // Antraciet color in hexadecimal
        case 'Creme':
            return ['#E9E0D2', 1]; // Antraciet color in hexadecimal
        case 'Black':
            return ['#0A0C10', 1]; // Antraciet color in hexadecimal
        case 'Helder - Polycarbonnat':
            return ['#FFFFFF', 0.7];
        case 'Opaal - Polycarbonnat':
            return ['#F0F8FF', 1];
        case 'Zonwerend - Polycarbonnat':
            return ['#FFD700', 0.8];
        case 'Glas':
            return ['#ccc', 0.25];
        case 'Getint Glas':
            return ['#023020', 0.9];
        case 'Melk Glas':
            return ['#FFFFFF', 0.8];
        default:
            return ['#00', 1]; // Return empty string for unknown colors
    }
};

export const useFormStore = create((set) => ({
    activeTab: 'maat', // Default active tab
    setActive: tab => set({ activeTab: tab }), // Function to set active tab
    formData: {
        // Initial form data
        maat: {
            breedte: "600",
            uitval: "300",
            doorloop_hoogte: "220",
            uitval_type: ""
        },
        uitval_type: "Buiten staander",
        kleur: {
            kleur_option: "Antraciet"
        },
        dak: {
            dak_option: "Helder - Polycarbonnat"
        },
        front: {
            goot_option: 'Rond'
        },
        total: {
            price: 200,
        }
    },
    setFormData: (data) => set({ formData: data }),

    getVerandaColor: () => {
        const state = useFormStore.getState();
        const { kleur_option } = state.formData.kleur;
        return getColorHex(kleur_option);
    },
    getDakColor: () => {
        const state = useFormStore.getState();
        const { dak_option } = state.formData.dak;
        return getColorHex(dak_option);
    },
    getNumberOfBeams: () => {
        const state = useFormStore.getState();
        const GlassWidth = 80;
        const { breedte } = state.formData.maat;

        return Math.ceil(breedte / GlassWidth);
    },
    calculateSizes: () => {
        // Calculate sizes based on formValues
        const state = useFormStore.getState();

        const { breedte, uitval, doorloop_hoogte } = state.formData.maat;

        // Perform calculations
        const skeletonBreedte = parseInt(breedte) / 600;
        const skeletonUitval = parseInt(uitval) / 300;
        const skeletonDoorloopHoogte = parseInt(doorloop_hoogte) / 220;

        // Update sizes in the store
        return [skeletonBreedte, skeletonDoorloopHoogte, skeletonUitval];
        /*     return {
                sizes: {
                    skeletonBreedte,
                    skeletonUitval,
                    skeletonDoorloopHoogte
                }
            }; */
    }
}));
