import React, { useState } from 'react';
import { useSnapshot } from "valtio";
import state from "../../state";


export const Roof = () => {
  const snapshot = useSnapshot(state);

  const handleOptionChange = (value) => {
    state.formData["roof"] = value;
    switch (value) {
      case 'Glass':
        state.items["roof"] = '#6795d6';
        break;
      case 'Opaal':
        state.items["roof"] = '#a7adb5';
        break;
      case 'Getint':
        state.items["roof"] = '#023d16';
        break;
      // Add more cases for other options as needed
      default:
        // Default case if the selected option doesn't match any case
        break;
    }
  };


  return (
    <div className={`toolbar expanded`}>
      <div className="options-container">
        <div className="options">
          <strong>Roof</strong>
          <p>
            <input
              type="radio"
              id="glass"
              name="roofOptions"
              value="Glass"
              checked={snapshot.formData.roof === 'Glass'}
              onChange={() => handleOptionChange('Glass')}
            />
            <label htmlFor="glass">Glass</label>
          </p>
          <p>
            <input
              type="radio"
              id="opaal"
              name="roofOptions"
              value="Opaal"
              checked={snapshot.formData.roof === 'Opaal'}
              onChange={() => handleOptionChange('Opaal')}
            />
            <label htmlFor="opaal">Opaal</label>
          </p>
          <p>
            <input
              type="radio"
              id="getint"
              name="roofOptions"
              value="Getint"
              checked={snapshot.formData.roof === 'Getint'}
              onChange={() => handleOptionChange('Getint')}
            />
            <label htmlFor="getint">Getint</label>
          </p>
          {/* Add more options as needed */}
        </div>
      </div>
    </div>
  );
};
