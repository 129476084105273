import React from 'react';

const ColorSelectWidget = (props) => {
    const { options, value, disabled, readonly, onChange } = props;

    // Function to get the background color based on the option value
    const getBackgroundColor = (optionValue) => {
        switch (optionValue) {
            case 'Antraciet':
                return '#383838'; // Background color for 'Antraciet'
            case 'Creme':
                return '#FFF8E7'; // Background color for 'Creme'
            case 'Black':
                return '#000000'; // Background color for 'Black'
            default:
                return '#FFFFFF'; // Default background color
        }
    };

    return (
        <div className="darzo-customfield-color">
            {options.enumOptions.map((option, index) => (
                <label key={index} className="">
                    <input
                        type="radio"
                        value={option.value}
                        checked={value === option.value}
                        onChange={() => onChange(option.value)}
                        disabled={disabled || readonly}
                        className="hidden"
                    />
                    <div
                        className=""
                        style={{
                            backgroundColor: getBackgroundColor(option.value),
                            border: value === option.value ? '2px solid #3182ce' : '2px solid transparent', // Adjust border based on selection
                        }}
                    />
                    <span className="block">{option.label}</span>
                </label>
            ))}
        </div>
    );
};

export default ColorSelectWidget;
