/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useEffect, useRef } from 'react'
import { TextureLoader, RepeatWrapping } from 'three';
import { useGLTF, PerspectiveCamera, Text } from '@react-three/drei'
import { useFormStore } from '../../state/FormStore';


export function Veranda(props) {
  const { nodes, materials } = useGLTF('/veranda3/veranda3.glb')
  const { formData, getVerandaColor, getDakColor, calculateSizes, getNumberOfBeams } = useFormStore();
  const verandaColor = getVerandaColor();
  const dakColor = getDakColor();
  const verandaSize = calculateSizes();
  const numberOfBeams = getNumberOfBeams();

  const textureLoader = new TextureLoader();


  var wallTexture = textureLoader.load('textures/bricks.jpg', function (texture) {
    texture.wrapS = texture.wrapT = RepeatWrapping;
    texture.offset.set(0, 0);
    texture.repeat.set(15, 15);
  });

  var woodTexture = textureLoader.load('textures/wood.jpg', function (texture) {
    texture.wrapS = texture.wrapT = RepeatWrapping;
    texture.offset.set(0, 0);
    texture.repeat.set(10, 10);

  });
  var opaalTexture = textureLoader.load('textures/opaal.jpg', function (texture) {
    texture.wrapS = texture.wrapT = RepeatWrapping;
    texture.offset.set(0, 0);
    texture.repeat.set(10, 10);

  });






  const verandaStartPosition = - formData.maat.breedte * 0.00101; //-verandaSize[0] / 2;
  const verandaMiddlePosition = 0;
  const verandaEndPosition = formData.maat.breedte * 0.00101;

  const gap = (formData.maat.breedte * 0.00204) / (numberOfBeams - 1);
  const activeGoot = formData.front.goot_option;

  const Beams = Array.from({ length: numberOfBeams }, (_, i) => {
    const beamPosition = verandaStartPosition + i * gap;

    return (
      <mesh
        key={i}
        castShadow
        receiveShadow
        geometry={nodes.initialShadingGrouppolySurface706_n3d.geometry}
        material={materials.skeleton_mat}
        position={[beamPosition, 0, 0]}
        scale={[1, 1, verandaSize[2]]}
      >
        <meshStandardMaterial attach="material" color={verandaColor[0]} />
      </mesh>
    );
  });


  return (
    <group {...props} dispose={null}>
      <PerspectiveCamera
        makeDefault={false}
        far={2}
        near={0}
        fov={22.895}
        position={[-1.778, 2.432, 2.325]}
        rotation={[-0.775, -0.541, -0.467]}
      />
      <Text
        color="black"
        fontSize={0.04}  // Smaller text size
        maxWidth={200}
        lineHeight={1}
        letterSpacing={0.02}
        textAlign={'center'}
        font="https://fonts.gstatic.com/s/roboto/v27/KFOmCnqEu92Fr1Mu4mxP.ttf"
        anchorX="center"
        anchorY="middle"
        position={[0, -0.04, 0.3]}
        rotation={[0, 0, 0]}  // No rotation
      >
        {formData.maat.breedte} MM
      </Text>

      <Text
        color="black"
        fontSize={0.04}  // Smaller text size
        maxWidth={200}
        lineHeight={1}
        letterSpacing={0.02}
        textAlign={'center'}
        font="https://fonts.gstatic.com/s/roboto/v27/KFOmCnqEu92Fr1Mu4mxP.ttf"
        anchorX="center"
        anchorY="middle"
        position={[0.7, -0.03, 0]}
        rotation={[0, Math.PI / 2, 0]}  // No rotation
      >
        {formData.maat.uitval} MM
      </Text>
      <Text
        color="black"
        fontSize={0.04}  // Smaller text size
        maxWidth={200}
        lineHeight={1}
        letterSpacing={0.02}
        textAlign={'center'}
        font="https://fonts.gstatic.com/s/roboto/v27/KFOmCnqEu92Fr1Mu4mxP.ttf"
        anchorX="center"
        anchorY="middle"
        position={[verandaEndPosition + 0.09, 0.2, 0.2]}
        rotation={[0, 0, Math.PI / 2]}  // No rotation
      >
        {formData.maat.doorloop_hoogte} MM
      </Text>


      <group position={[-0, 0.0, 0.0]}>
        {/*   <mesh
          castShadow
          receiveShadow
          geometry={nodes.spie_mat_right.geometry}
          material={materials.sprt_mat}
        /> */}
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.roof_mesh.geometry}
          material={materials.roof_mat}
          scale={[verandaSize[0] - 0.004, 1, verandaSize[2]]}>
          <meshStandardMaterial attach="material" map={opaalTexture} color={dakColor[0]} transparent opacity={dakColor[1]} />
        </mesh>


        <mesh
          castShadow
          receiveShadow
          geometry={nodes.wall_mesh_n3d.geometry}
          material={materials.wall_mat}
          scale={[verandaSize[0], 1, verandaSize[2]]}
          position={[0, 0, 0]}
        >
          <meshStandardMaterial map={wallTexture} attach="material" />
        </mesh>

        <mesh
          castShadow
          receiveShadow
          geometry={nodes.floor_mesh_n3d.geometry}
          material={materials.floor_mat}
          scale={[verandaSize[0], 1, verandaSize[2]]}>
          <meshStandardMaterial map={woodTexture} attach="material" />
        </mesh>
        {/*   <mesh
          castShadow
          receiveShadow
          geometry={nodes.spie_mat_left.geometry}
          material={materials.splft_mat}
        /> */}
        {/*     <mesh
          castShadow
          receiveShadow
          geometry={nodes.mesh_1.geometry}
          material={materials.skeleton_mat}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.mesh_1_1.geometry}
          material={materials.Unused}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.mesh_5.geometry}
          material={materials.skeleton_mat}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.mesh_5_1.geometry}
          material={materials.Unused}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.mesh_7.geometry}
          material={materials['door-handle']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.mesh_7_1.geometry}
          material={materials.skeleton_mat}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.mesh_7_2.geometry}
          material={materials.rail}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.mesh_7_3.geometry}
          material={materials.roof_mat}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.mesh_8.geometry}
          material={materials['door-handle']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.mesh_8_1.geometry}
          material={materials.skeleton_mat}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.mesh_8_2.geometry}
          material={materials.rail}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.mesh_8_3.geometry}
          material={materials.roof_mat}
        /> */}
      </group>
      <group position={[-0.000, -0.0, -0.0]}>
        {/*   <mesh
          castShadow
          receiveShadow
          geometry={nodes.mesh_9.geometry}
          material={materials.roof_mat}
        /> */}
        {/*         <mesh
          castShadow
          receiveShadow
          geometry={nodes.mesh_9_1.geometry}
          material={materials.skeleton_mat}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.mesh_10.geometry}
          material={materials.skeleton_mat}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.mesh_10_1.geometry}
          material={materials.roof_mat}
        /> */}
      </group>
      <group position={[0, 0, 0.0]}>
        {/*   <mesh
          castShadow
          receiveShadow
          geometry={nodes.Leg_R.geometry}
          scale={[1, verandaSize[1], 1]}
          material={materials.skeleton_mat}>  <meshStandardMaterial attach="material" color={verandaColor[0]} />
        </mesh> */}
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Leg_Mid.geometry}
          scale={[1, 1, verandaSize[2]]}
          position={[verandaStartPosition, 0, 0]}
          material={materials.skeleton_mat}>  <meshStandardMaterial attach="material" color={verandaColor[0]}
          />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Leg_Mid.geometry}
          scale={[1, 1, verandaSize[2]]}
          position={[verandaMiddlePosition, 0, 0]}
          material={materials.skeleton_mat}>  <meshStandardMaterial attach="material" color={verandaColor[0]}
          />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Leg_Mid.geometry}
          scale={[1, 1, verandaSize[2]]}
          position={[verandaEndPosition, 0, 0]}
          material={materials.skeleton_mat}>  <meshStandardMaterial attach="material" color={verandaColor[0]}
          />
        </mesh>
        {/*   <mesh
          castShadow
          receiveShadow
          geometry={nodes.Leg_Left.geometry}
          scale={[1, verandaSize[1], 1]}
          position={[0, 0, 0]}
          material={materials.skeleton_mat}>  <meshStandardMaterial attach="material" color={verandaColor[0]}

          />
        </mesh> */}
      </group>
      <group position={[0, 0, 0]}>
        /*CENTER THE BEAMS GROUP ACCORING TO WALL SIZE AND POSITION */
        {Beams}
      </group>

      {/*       <mesh
        castShadow
        receiveShadow
        geometry={nodes.initialShadingGrouppolySurface706_n3d.geometry}
        material={materials.skeleton_mat}
      /> */}
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.polySurface433_n3d.geometry}
        //  material={materials.rubber_mat}
        scale={[verandaSize[0], 1, verandaSize[2]]}>
        <meshStandardMaterial attach="material" color={verandaColor[0]} />

      </mesh>
      {/*   <mesh
        castShadow
        receiveShadow
        geometry={nodes.Round_Gutter.geometry}
        material={materials['skeleton_mat.011']}
        scale={[verandaSize[0], 1, verandaSize[2]]}>
        <meshStandardMaterial attach="material" color={verandaColor[0]} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Klasssiek_Gutter.geometry}
        material={materials['skeleton_mat.011']}
        scale={[verandaSize[0], 1, verandaSize[2]]}>
        <meshStandardMaterial attach="material" color={verandaColor[0]} />
      </mesh> */}
      {/*       <mesh
        castShadow
        receiveShadow
        geometry={nodes.Flat_Gutter.geometry}
        material={materials['skeleton_mat.011']}
        scale={[verandaSize[0], 1, verandaSize[2]]}>
        <meshStandardMaterial attach="material" color={verandaColor[0]} />
      </mesh> */}
      {/*   <mesh
        castShadow
        receiveShadow
        geometry={nodes.polySurface574_n3d.geometry}
        material={materials.skeleton_mat}
        scale={[verandaSize[0], 1, verandaSize[2]]}>
        <meshStandardMaterial attach="material" color={verandaColor[0]} />
      </mesh> */}


      {/*   <mesh
        castShadow
        receiveShadow
        geometry={nodes.top_gutter.geometry}
        material={materials.skeleton_mat}
      /> */}
      {activeGoot === 'Klassiek' && (
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Klasssiek_Gutter.geometry}
          material={materials['skeleton_mat.011']}
          scale={[verandaSize[0], 1, verandaSize[2]]}>
          <meshStandardMaterial attach="material" color={verandaColor[0]} />
        </mesh>
      )}
      {activeGoot === 'Vierkant' && (
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Flat_Gutter.geometry}
          material={materials['skeleton_mat.011']}
          scale={[verandaSize[0], 1, verandaSize[2]]}>
          <meshStandardMaterial attach="material" color={verandaColor[0]} />
        </mesh>
      )}
      {activeGoot === 'Rond' && (
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Round_Gutter.geometry}
          material={materials.skeleton_mat}
          scale={[verandaSize[0], 1, verandaSize[2]]}>
          <meshStandardMaterial attach="material" color={verandaColor[0]} />
        </mesh>
      )}
      <group>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.breedth_arrow.geometry}
          material={materials['Material.001']}
          scale={[verandaSize[0], 1, verandaSize[2]]}>
          <meshStandardMaterial attach="material" map={opaalTexture} color="#000000" transparent opacity="0.3" />
        </mesh>

        <mesh
          castShadow
          receiveShadow
          geometry={nodes.uitval_arrow.geometry}
          material={materials['Material.001']}>
          <meshStandardMaterial attach="material" map={opaalTexture} color="#000000" transparent opacity="0.3" />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.doorloop_arrow.geometry}
          material={materials['Material.001']}
          position={[verandaEndPosition - 0.6, 0, 0]}>
          <meshStandardMaterial attach="material" map={opaalTexture} color="#000000" transparent opacity="0.3" />
        </mesh>
      </group>
    </group >
  )
}

useGLTF.preload('/veranda3/veranda4.glb')
