const ImageRadioWidget = (props) => {
    const { options, value, disabled, readonly, onChange } = props;

    return (
        <div className="darzo-customfield-imgradio">
            {options.enumOptions.map((option, index) => {
                const inputId = `img-radio-${index}${option.value}`;
                return (
                    <div key={index} className="img-radio">
                        <input
                            type="radio"
                            id={inputId}
                            value={option.value}
                            checked={value === option.value}
                            onChange={(event) => onChange(event.target.value)}
                            disabled={disabled || readonly}
                            className=""
                        />
                        <label htmlFor={inputId} className="block relative cursor-pointer">
                            <img
                                src={`filterimgs/${option.value}.webp`} // Replace with your image path
                                alt={option.label}
                                className={` ${value === option.value ? '' : ''}`}
                            />
                            <span className="text-xs">{option.label}</span>
                        </label>
                    </div>
                );
            })}
        </div>
    );
};

export default ImageRadioWidget;
