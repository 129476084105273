import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './index.css'; // Import the CSS file for styling
import Configurator from './components/Configurator';
import OrderDetails from './components/OrderDetails';

function App() {

  return (

    <Router>
      <Routes>
        <Route exact path="/" element={<Configurator />} />
        <Route exact path="/order-details" element={<OrderDetails />} />
      </Routes>
    </Router>
  );
}

export default App;
