import { Suspense } from 'react';
import { Canvas } from '@react-three/fiber';
import { Environment, OrbitControls } from '@react-three/drei';
import { Veranda } from '../components';
import { useState } from 'react';

// Constants for environment presets and lighting intensity
const ENVIRONMENT_PRESET = "night"; // sunset, dawn, night, warehouse, forest, apartment, studio, city, park, lobby
const ENVIRONMENT_HDRI_URL = '/sky/venice_sunset_1k.hdr';

// Camera settings
const cameraSettings = {
    position: [-1.5, 3, 2], // Adjusted position
    fov: 25,
    near: 0.1,
    far: 10 // Adjusted far value
};

const OrderDetails = () => {
    const [selectedProduct, setSelectedProduct] = useState(null);

    const products = [
        { id: 1, name: "Product 1", price: "$10", quantity: 2, length: "10cm", code: "P001" },
        { id: 2, name: "Product 2", price: "$20", quantity: 1, length: "15cm", code: "P002" },
        { id: 3, name: "Product 3", price: "$30", quantity: 3, length: "12cm", code: "P003" },
    ];

    const handleProductClick = (product) => {
        setSelectedProduct(product);
    };

    return (
        <div className="flex flex-col md:flex-row h-screen">
            <div className="md:w-1/2 h-1/2 md:h-full">
                <div className="darzo-craft-container h-full">
                    <Canvas shadows dpr={[1, 1]} style={{ background: '#606074' }} camera={cameraSettings}>
                        <Suspense fallback={null}>
                            <directionalLight castShadow intensity={0.1} position={[2, 0, 0]} />
                            <Veranda />
                            <Environment files={ENVIRONMENT_HDRI_URL} blur={1} background={true} />
                        </Suspense>
                        <OrbitControls enablePan={true} enableZoom={true} enableRotate={true} enableDamping={true} dampingFactor={0.04} minDistance={1} maxDistance={5} maxPolarAngle={Math.PI / 1.8} />
                    </Canvas>
                </div>
            </div>
            <div className="md:w-1/2 h-1/2 md:h-full p-4 overflow-auto">
                <h2 className="text-xl font-bold mb-4">Products</h2>
                <div className="overflow-x-auto">
                    <table className="min-w-full table-auto">
                        <thead>
                            <tr>
                                <th className="px-4 py-2">Name</th>
                                <th className="px-4 py-2">Price</th>
                                <th className="px-4 py-2">Quantity</th>
                                <th className="px-4 py-2">Length</th>
                                <th className="px-4 py-2">Code</th>
                            </tr>
                        </thead>
                        <tbody>
                            {products.map((product) => (
                                <tr key={product.id} className={`cursor-pointer ${selectedProduct === product ? 'bg-gray-200' : ''}`} onClick={() => handleProductClick(product)}>
                                    <td className="border px-4 py-2">{product.name}</td>
                                    <td className="border px-4 py-2">{product.price}</td>
                                    <td className="border px-4 py-2">{product.quantity}</td>
                                    <td className="border px-4 py-2">{product.length}</td>
                                    <td className="border px-4 py-2">{product.code}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default OrderDetails;
