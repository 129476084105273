import { Suspense, useState, useEffect } from 'react';
import { Canvas } from '@react-three/fiber';
import { Environment, OrbitControls } from '@react-three/drei';
import { Veranda, Toolbar } from '../components';
import { SideDrawer } from '../components/Toolbar/SideDrawer';
import { useNavigate } from 'react-router-dom';
import { ConfigurationForm } from './Toolbar/ConfigurationForm';
import { useFormStore } from '../state/FormStore';
const ENVIRONMENT_PRESET = "night";
const AMBIENT_LIGHT_INTENSITY = 0.2;
const SPOT_LIGHT_INTENSITY = 0;
const ENVIRONMENT_HDRI_URL = '/sky/venice_sunset_1k.hdr';

const desktopCameraSettings = {
    position: [-1.5, 3, 2],
    fov: 25,
    near: 0.1,
    far: 10
};

const mobileCameraSettings = {
    position: [-3.5, 3, 2],
    fov: 35, // Adjust fov for mobile
    near: 0.1,
    far: 10
};

const Carousel = () => {
    const setActive = useFormStore(state => state.setActive); // Get the setActive function from the Zustand store
    const activeTab = useFormStore(state => state.activeTab); // Get the activeTab from the Zustand store
    //const { cameraSettings, setCameraSettings } = useThreeStore();


    // Function to handle tab click and set active state
    const handleTabClick = tabName => {
        setActive(tabName);
        changeCameraPosition(tabName);

    };
    const changeCameraPosition = tabName => {
        let position = [0, 0, 5000];
        //setCameraSettings({ ...cameraSettings, position: position });
        //console.log(cameraSettings);
    };

    useEffect(() => {
        const rootFieldsets = document.querySelectorAll('fieldset[id^="root_"]');
        rootFieldsets.forEach(fieldset => {
            fieldset.style.display = 'none';
        });

        // Show the fieldset with the activeTab value
        const activeFieldset = document.getElementById(`root_${activeTab}`);
        if (activeFieldset) {
            activeFieldset.style.display = 'flex';
        }

    }, [activeTab]);


    const items = [
        { id: 1, label: 'Maat' },
        { id: 2, label: 'Uitval Type' },
        { id: 3, label: 'Kleur' },
        { id: 4, label: 'Dak' },
        //{ id: 5, label: 'Floor' },
        { id: 6, label: 'Front' },
        //{ id: 7, label: 'Left' },
        //{ id: 8, label: 'Right' },
    ];

    return (
        <div className="w-full overflow-x-scroll no-scrollbar mobile-toolbar">
            <div className="flex space-x-2">
                {items.map(item => (
                    <button
                        key={item.id}
                        className="flex flex-col items-center justify-center p-2 bg-gray-900 rounded-lg focus:outline-none"
                        onClick={() => handleTabClick(item.label.toLowerCase().replace(/ /g, '_'))}
                    >
                        <span className="mb-1"><img src={`icons/${item.label}.png`} /></span>
                        <span className="text-xs text-white">{item.label}</span>
                    </button>
                ))}
            </div>
        </div>
    );
};



const MobileScrollableButtons = () => {
    return (
        <div className="fixed bottom-0 left-0 right-0  ">
            <Carousel />
            <div className="overflow-x-scroll  mobile-configuration-form">
                <div className="mobile-configuration-form-content  text-white no-scrollbar" >
                    <ConfigurationForm />
                </div>
            </div>
        </div>
    );
};

const DesktopContent = ({ handlePlaceOrder }) => (
    <div className="content-container text-gray-700 bg-gray-200 h-full w-full">
        <div className="darzo-craft-container h-screen">
            <Canvas shadows dpr={[1, 1]} style={{ background: '#606074' }} camera={desktopCameraSettings}>
                <Suspense fallback={null} >
                    <directionalLight castShadow intensity={0.1} position={[2, 0, 0]} />
                    <Veranda />
                    <Environment files={ENVIRONMENT_HDRI_URL} blur={1} background={true} />
                </Suspense>
                <OrbitControls enablePan={true} enableZoom={true} enableRotate={true} enableDamping={true} dampingFactor={0.04} minDistance={1} maxDistance={6} maxPolarAngle={Math.PI / 1.8} />
            </Canvas>
            <div className="order-info">
                <span>Totaal : €20,000</span>
                <button onClick={handlePlaceOrder}>Place an order</button>
            </div>
        </div>
    </div>
);

const MobileContent = () => (
    <div className="content-container text-gray-700 bg-gray-200 h-full w-full">
        <div className="darzo-craft-container h-screen relative">
            <div className="mobile-canvas-container h-screen">
                <Canvas shadows dpr={[1, 1]} style={{ background: '#606074' }} camera={mobileCameraSettings}>
                    <Suspense fallback={null}>
                        <directionalLight castShadow intensity={0.1} position={[2, 0, 0]} />
                        <Veranda />
                        <Environment files={ENVIRONMENT_HDRI_URL} blur={1} background={true} />
                    </Suspense>
                    <OrbitControls enablePan={true} enableZoom={true} enableRotate={true} enableDamping={true} dampingFactor={0.04} minDistance={1} maxDistance={6} maxPolarAngle={Math.PI / 1.8} />

                </Canvas>
            </div>
            <div className="mobile-buttons-container absolute bottom-0 left-0 right-0   overflow-y-scroll ">
                <MobileScrollableButtons />
            </div>
        </div>
    </div>
);

const Configurator = () => {
    const navigate = useNavigate();
    const handlePlaceOrder = () => {
        navigate('/order-details');
    };

    const [isMobileLayout, setIsMobileLayout] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobileLayout(window.innerWidth < 1000); // Adjust breakpoint as per your design
            //setIsMobileLayout(true);
        };

        handleResize(); // Initial check
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className={`darzo-craft-body flex ${isMobileLayout ? 'flex-col-reverse' : 'h-screen'}`}>
            {!isMobileLayout && (
                <div className="sidebar w-3/12">
                    <div className='flex'>
                        <Toolbar />
                        <SideDrawer />
                    </div>
                </div>
            )}
            {isMobileLayout ? <MobileContent /> : <DesktopContent handlePlaceOrder={handlePlaceOrder} />}
        </div>
    );
}

export default Configurator;
